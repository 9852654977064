<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col xs="12" lg="12">
        <b-card>
          <h4><i class="fal fa-users"></i> All Vendors</h4>
          <hr>
          <v-client-table :columns="columns" :data="wssData" :options="options" :theme="theme" id="dataTable">
            <!-- <div slot="status" slot-scope="props">
              <span v-if="props.row.status === 0">
                <button class="btn btn-success w-100">Active</button>
              </span>
              <span v-if="props.row.status !== 0">
                <button class="btn btn-danger w-100">Inactive</button>
              </span>
            </div> -->
            <span slot="name" slot-scope="props"><router-link :to="{ name: 'Company', params: { id: props.row.companyid } }">{{props.row.name}}</router-link></span>

            <div slot="services" slot-scope="props">
              <b-button v-b-modal.assignmentsWts @click="sendVendorId(props.row)" class="btn-sm" variant="success">View Services <i class="fa fa-eye"></i></b-button>
            </div>
          </v-client-table>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="assignmentsWts" ref="assignmentsWts" title="Vendor Service Assignments" title-tag="h2" centered size="lg" hide-footer>
      <b-input-group class="mb-3">
        <b-input-group-prepend style="z-index: 99;">
          <b-input-group-text>
            <i class="fa fa-cog"></i>
          </b-input-group-text>
        </b-input-group-prepend>
        <select class="select-account form-control" @change="loadServices()" name="projectid" v-model="projectid">
          <option value="" disabled selected>Select Project</option>
          <option for v-for="option in projectData"  v-bind:value="option.projectid">{{option.projectname}}</option>
        </select>
      </b-input-group>

      <v-client-table :columns="columnsServices" :data="wtsVendorData" :options="optionsServices" :theme="theme" id="dataTable">
        <div slot="status" slot-scope="props">
          <span v-if="props.row.status === 0">
            <button class="btn btn-sm btn-success w-100">Active</button>
          </span>
          <span v-if="props.row.status === 1">
            <button class="btn btn-sm btn-danger w-100">Inactive</button>
          </span>
        </div>

        <div slot="delete" slot-scope="props">
          <b-button v-if="props.row.status === 1" variant="success" class="w-100 btn-sm" @click="sendEditInfo(props.row.vasid, props.row.serviceid, props.row.vid, props.row.status)"><i class="fa fa-check"></i></b-button>

          <b-button v-if="props.row.status === 0" variant="danger" class="w-100 btn-sm" @click="sendEditInfo(props.row.vasid, props.row.serviceid, props.row.vid, props.row.status)"><i class="fa fa-times"></i></b-button>
        </div>
      </v-client-table>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)

export default {
  name: 'WtsVendor',
  components: {
    ClientTable,
    Event,
  },
  data () {
    return {
      columns: ['fname', 'lname', 'email', 'vidcode',  'services'],
      wssData: [],

      options: {
        headings: {
          fname: 'First Name',
          lname: 'Last Name',
          vidcode: 'Vendor ID',
        },
        sortable: ['companyid', 'email', 'fname', 'lname', 'vidcode'],
        filterable: ['companyid', 'email', 'fname', 'lname' , 'vidcode'],
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          chunk: 15,
        }
      },

      columnsServices: ['status', 'name', 'type', 'delete'],
      optionsServices: {
        headings: {
          name: 'Service Name',
          type: 'Code',
          delete: 'Change Status',
        },
        sortable: ['name', 'status', 'type'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        pagination: {
          dropdown: false
        },
        perPage: 5,
      },
      theme: 'bootstrap4',
      template: 'default',
      useVuex: false,

      error: false,
      passworderror: false,
      projectData: [],
      projectid: 0,
      unique: false,
      assignerror: false,
      passlimit: false,
      wtsAssignmentData: [],
      wtsFilteredData: [],
      wtsVendorData: [],
      editprojectid: "",
      editwssid: "",
      editstatus: "",
      editpasslimit: "",
      edit: "",
      editid: "",
      editassignerror: false,

      serviceid: '',
      vid: '',
      status: "",
      id: "",
    }
  },
  methods: {
    sendVendorId(props){
      this.wtsVendorData = [];
      this.projectid = "";
      this.vid = props.vid;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.editid,
            projectid: this.editprojectid,
            wssid: this.editwssid,
            status: this.editstatus
          })
          this.$http.post('/wts/edit/wss_project', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Assignment Edited', {icon: 'fa-check', type: 'success', duration: '3000'})
              this.$refs.editassignmentmodal.hide();
            }
          })
          .catch(error => {
            this.editassignerror = true;
          })
          return;
        }
      });
    },
    sendEditInfo(id, serviceid, vid, status) {
      if(status === 1){
        status = 0;
      }
      else {
        status = 1;
      }
      let data = JSON.stringify({
        id: id,
        serviceid: serviceid,
        projectid: this.projectid,
        vid: vid,
        status: status
      })
      this.$http.post('/wts/edit/vas', data)
      .then(response => {
        this.loadServices();
      }).catch(function (error) {
      })
    },
    loadData() {
      this.$http.get('/wts/get/vendors')
      .then(response => {
        if (response.status === 200) {
          this.wssData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
      this.$http.get('/wts/get/projects')
      .then(response => {
        if (response.status === 200) {
          this.projectData = response.data.result;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    },
    loadServices() {
      this.$http.get('/wts/get/vas/' + this.projectid)
      .then(response => {
        if (response.status === 200) {
          var temp = response.data.result;
          this.wtsVendorData = temp.filter(x => x.vid === this.vid)
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
      })
    }
  },
  mounted: function () {
    this.loadData();
  },
}
</script>
<style>
  .VueTables__limit,
  .VueTables__search {
    display: none !important;
  }
</style>
